import React, { useState } from 'react'
import toast from 'react-hot-toast'
// Context
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { AS } from './styled'

const DeleteAutoshipModal = ({ open, setShowModal }) => {
  const {
    prismicData: {
      prismicGeneral,
      prismicAutoshipPage: {
        cancel_my_autoship,
        keep_my_autoship,
        skip_my_next_autoship,
      },
    },
  } = usePrismic()
  const { autoShipDate, autoShipId, manageAutoShip, updateAutoShipState } =
    useCartContext()

  const [isLoading, setIsLoading] = useState(false)

  const handleSkipNextAutoship = async () => {
    setIsLoading(true)
    await manageAutoShip.skipMonth(autoShipId, updateAutoShipState).then(() => {
      setIsLoading(false)
      setShowModal(false)
      toast.success(prismicGeneral.msg_subscription_skipped, {
        icon: '📆',
      })
    })
  }

  const handleCancelAutoship = async () => {
    setIsLoading(true)
    const data = {
      autoShipId,
      dateNextProcess: autoShipDate,
      isActive: false,
    }
    await manageAutoShip
      .update(data, updateAutoShipState, prismicGeneral)
      .then(() => {
        setIsLoading(false)
        setShowModal(false)
        toast.success(prismicGeneral.msg_subscription_cancelled, {
          icon: '❌',
        })
      })
  }

  const handleKeepAutoship = () => {
    setShowModal(false)
  }

  return (
    <AS.StyledModal
      centered={true}
      closeIcon
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={() => setShowModal(false)}
      open={open}
    >
      <AS.StyledHeader />
      <AS.StyledModalContent>
        <AS.Container>
          {/* TODO - Add AS reward points when available */}
          {/* <AS.StyledSpan>
            If you cancel your autoship you will lose the 120 rewards points you
            have earned.
          </AS.StyledSpan> */}

          <AS.StyledSpan>
            {prismicGeneral.msg_subscription_skip_rewards_points.replace(
              '{date}',
              autoShipDate
            )}
          </AS.StyledSpan>

          <AS.ButtonsWrapper>
            {/*<AS.StyledButton*/}
            {/*  content={skip_my_next_autoship[0].text}*/}
            {/*  data-qa="skip-my-next-autoship"*/}
            {/*  disabled={isLoading}*/}
            {/*  loading={isLoading}*/}
            {/*  onClick={handleSkipNextAutoship}*/}
            {/*/>*/}

            <AS.StyledButton
              content={cancel_my_autoship[0].text}
              data-qa="skip-my-next-autoship"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleCancelAutoship}
            />

            <AS.StyledButton
              content={keep_my_autoship[0].text}
              data-qa="skip-my-next-autoship"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleKeepAutoship}
            />
          </AS.ButtonsWrapper>
        </AS.Container>
      </AS.StyledModalContent>
    </AS.StyledModal>
  )
}

export default DeleteAutoshipModal
