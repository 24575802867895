import React from 'react'
// Context
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
// Styles
import { Wrapper, Container, DiscountText } from './OrderDetails.styles'
// Utils
import { getOrderDetailValues } from '../../utils/cartHelpers'
import { formatCurrency } from '../../utils/i18n/stores'

const OrderDetails = ({
  prices,
  shipping,
  showShippingAndTax,
  showWholesaleTotal,
  total_item_count,
  total_quantity,
  wholesale_total,
  shippingTotal,
  taxValue,
  totalPV,
}) => {
  const {
    langAndCountry,
    storeCountryCode,
    prismicData: {
      prismicGeneral,
      prismicCartPage: {
        discount: discountText,
        items,
        shipping: shippingPrismicText,
        sub_total,
        tax,
        total,
        handling,
        handling_price,
      },
    },
  } = usePrismic()

  const calculateWholesaleTotal = (wholesale_total, prices) => {
    if (prices?.discounts) {
      prices?.discounts?.forEach(discount => {
        wholesale_total -= discount?.amount?.value
      })
      return wholesale_total
    }
    return wholesale_total
  }

  const { userType } = useAuthContext()
  const { isAmbOfferInCart, isPcOfferInCart } = useCartContext()
  const discountSubTotal =
    isAmbOfferInCart || isPcOfferInCart
      ? calculateWholesaleTotal(wholesale_total, prices)
      : prices?.subtotal_with_discount_excluding_tax?.value ||
        prices?.subtotal_excluding_tax

  const { getAmounts, getTotalWithShipping } = getOrderDetailValues({
    ...prices,
    shipping,
  })
  const { subTotalText, taxesText, shippingText } = getAmounts()

  const showDiscount = () => {
    if (!prices?.discounts) return null

    return prices?.discounts?.map((discount, i) => {
      const discountLabel = discount?.label ?? discountText[0].text
      const discountValue =
        formatCurrency(langAndCountry, discount?.amount?.value) ?? ''
      return (
        <Container key={`${discount.label}-${i}`}>
          <DiscountText>{discountLabel}</DiscountText>
          <DiscountText>{discountValue}</DiscountText>
        </Container>
      )
    })
  }

  const showTotal = total_item_count ? total_item_count : total_quantity
  // make sure shipping and handling doesn't show negative - don't subtract if there is no shipping cost
  const shippingMinusHandling =
    shippingText > handling_price && handling_price
      ? shippingText - handling_price
      : shippingText
  const handlingPrice = shippingTotal > handling_price ? handling_price : 0
  const showTax = storeCountryCode !== 'au' && storeCountryCode !== 'nz'

  return (
    <Wrapper>
      <Container>
        <span data-qa="subTotalText">{`${sub_total[0].text} (${showTotal} ${items[0].text})`}</span>
        <span data-qa="subTotal">
          {showWholesaleTotal
            ? formatCurrency(langAndCountry, wholesale_total)
            : formatCurrency(langAndCountry, subTotalText)}
        </span>
      </Container>
      {showDiscount()}
      {showShippingAndTax ? (
        <>
          {showTax && (
            <Container>
              <span data-qa="taxText">{tax[0].text}</span>
              <span data-qa="tax">
                {formatCurrency(langAndCountry, taxesText)}
              </span>
            </Container>
          )}
          <Container>
            <span data-qa="shippingText">{shippingPrismicText[0].text}</span>
            <span data-qa="shipping">
              {formatCurrency(langAndCountry, shippingMinusHandling)}
            </span>
          </Container>
          {handling_price > 0 && (
            <Container>
              <span data-qa="handlingText">{handling[0].text}</span>
              <span data-qa="handling">
                {formatCurrency(langAndCountry, handlingPrice)}
              </span>
            </Container>
          )}
        </>
      ) : null}
      <Container total>
        <span data-qa="totalText">{total[0].text}</span>
        <span data-qa="total">
          {showWholesaleTotal
            ? formatCurrency(
                langAndCountry,
                discountSubTotal + taxValue + shippingTotal
              )
            : formatCurrency(langAndCountry, getTotalWithShipping())}
        </span>
      </Container>
      {userType === 'AMBASSADOR' || isAmbOfferInCart ? (
        <Container>
          <span data-qa="totalPVText">{prismicGeneral.msg_pv_total}</span>
          <span data-qa="totalPV">{totalPV}</span>
        </Container>
      ) : null}
    </Wrapper>
  )
}

export default OrderDetails
