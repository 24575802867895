import { gql } from 'graphql-request'

export const GET_CARDS_ON_FILE = gql`
  mutation GetCardsOnFile($associateId: Int!) {
    nexioCreditCardsForAssociate(associateId: $associateId) {
      associateId
      associatePaymentInformationId
      dateCreated
      dateModified
      companyName
      creditCardGuid
      creditCardType
      lastFourDigits
      expirationDate
      active
    }
  }
`

export const GET_ONE_TIME_NEXIO_TOKEN = gql`
  mutation OneTimeToken {
    nexioOneTimeTokenIframe {
      token
    }
  }
`

export const SAVE_CARD_TO_ASSOCIATE = gql`
  mutation SaveCardDataV2($inputSaveCardData: InputSaveCardDataInput!) {
    nexioSaveCardDataV2(inputSaveCardData: $inputSaveCardData) {
      value
    }
  }
`
export const NEXIO_PROCESS_TRANSACTION = gql`
  mutation NexioProcessTransaction(
    $magentoOrderId: String!
    $creditCardGuid: String!
    $customerRedirectUrl: String!
  ) {
    nexioProcessTransaction(
      input: {
        magentoOrderId: $magentoOrderId
        creditCardGuid: $creditCardGuid
        customerRedirectUrl: $customerRedirectUrl
      }
    ) {
      success
      redirect
      redirectResult {
        redirectUrl
        pendingTransactionUid
      }
      error
      errorResult {
        error
        message
      }
    }
  }
`
export const NEXIO_PROCESS_TRANSACTION_V2 = gql`
  mutation NexioProcessTransactionV2(
    $magentoOrderIncrementId: String!
    $creditCardGuid: String!
    $customerRedirectUrl: String!
  ) {
    nexioProcessTransactionV2(
      input: {
        magentoOrderIncrementId: $magentoOrderIncrementId
        creditCardGuid: $creditCardGuid
        customerRedirectUrl: $customerRedirectUrl
      }
    ) {
      success
      redirect
      redirectResult {
        redirectUrl
        pendingTransactionUid
      }
      error
      errorResult {
        error
        message
      }
    }
  }
`
export const NEXIO_CONFIRM_TRANSACTION = gql`
  mutation NexioConfirmTransaction(
    $pendingTransactionUid: UUID!
    $cancel: Boolean!
  ) {
    nexioConfirmTransaction(
      input: { pendingTransactionUid: $pendingTransactionUid, cancel: $cancel }
    ) {
      message
    }
  }
`

export const PROCESS_PAYPAL_ORDER = gql`
  mutation ProcessPayPalOrder(
    $magentoOrderId: String!
    $payPalOrderId: String!
  ) {
    processPayPalOrder(
      input: { magentoOrderId: $magentoOrderId, payPalOrderId: $payPalOrderId }
    ) {
      success
    }
  }
`

export const PROCESS_PAYPAL_ORDER_V2 = gql`
  mutation ProcessPayPalOrderV2(
    $magentoOrderIncrementId: String!
    $payPalOrderId: String!
  ) {
    processPayPalOrderV2(
      input: {
        magentoOrderIncrementId: $magentoOrderIncrementId
        payPalOrderId: $payPalOrderId
      }
    ) {
      success
    }
  }
`

export const PROCESS_PAYPAL_ORDER_V3 = gql`
  mutation ProcessPayPalOrderV3(
    $magentoOrderIncrementId: String!
    $payPalOrderId: String!
  ) {
    processPayPalOrderV3(
      input: {
        magentoOrderIncrementId: $magentoOrderIncrementId
        payPalOrderId: $payPalOrderId
      }
    ) {
      success
    }
  }
`

export const DELETE_PAYMENT_METHOD = gql`
  mutation InactivatePaymentInfo($associatePaymentInformationId: Int!) {
    inactivatePaymentInformation(
      input: { associatePaymentInformationId: $associatePaymentInformationId }
    ) {
      isSuccess
      msg
    }
  }
`
