import {
  GET_CARDS_ON_FILE,
  GET_ONE_TIME_NEXIO_TOKEN,
  SAVE_CARD_TO_ASSOCIATE,
  PROCESS_PAYPAL_ORDER,
  PROCESS_PAYPAL_ORDER_V2,
  PROCESS_PAYPAL_ORDER_V3,
  NEXIO_PROCESS_TRANSACTION,
  NEXIO_CONFIRM_TRANSACTION,
  NEXIO_PROCESS_TRANSACTION_V2,
  DELETE_PAYMENT_METHOD,
} from '../graphql/payments'

import {
  CreditCard,
  CreditCardsForAssociateResponse,
  NexioProcessTransactionResponse,
  NexioProcessTransData,
  NexioProcessTransDataV2,
  OneTimeTokenResponse,
  NexioConfirmTransactionResponse,
  NexioConfirmTransData,
} from '../../../types/PaymentsTypes'

export const Payments = client => ({
  getNexioOneTimeCode: async (): Promise<OneTimeTokenResponse> => {
    const {
      nexioOneTimeTokenIframe: { token },
    } = await client.request(GET_ONE_TIME_NEXIO_TOKEN)
    return token
  },
  getCardsOnFile: async (
    associateId: number
  ): Promise<CreditCardsForAssociateResponse> => {
    const result = await client.request(GET_CARDS_ON_FILE, {
      associateId,
    })
    return result.nexioCreditCardsForAssociate
  },
  nexioProcessTransaction: async (
    data: NexioProcessTransData
  ): Promise<NexioProcessTransactionResponse> => {
    const { nexioProcessTransaction } = await client.request(
      NEXIO_PROCESS_TRANSACTION,
      data
    )
    return nexioProcessTransaction
  },
  nexioProcessTransactionV2: async (
    data: NexioProcessTransDataV2
  ): Promise<NexioProcessTransactionResponse> => {
    const { nexioProcessTransactionV2 } = await client.request(
      NEXIO_PROCESS_TRANSACTION_V2,
      data
    )
    return nexioProcessTransactionV2
  },
  nexioConfirmTransaction: async (
    data: NexioConfirmTransData,
    token?: string
  ): Promise<NexioConfirmTransactionResponse> => {
    if (token) {
      const { nexioConfirmTransaction } = await client.request(
        NEXIO_CONFIRM_TRANSACTION,
        data,
        { authorization: `Bearer ${token}` }
      )
      return nexioConfirmTransaction
    } else {
      const { nexioConfirmTransaction } = await client.request(
        NEXIO_CONFIRM_TRANSACTION,
        data
      )
      return nexioConfirmTransaction
    }
  },
  saveCardData: async (data: CreditCard) =>
    await client.request(SAVE_CARD_TO_ASSOCIATE, data),
  processPayPalOrder: async data => {
    const result = await client.request(PROCESS_PAYPAL_ORDER, data)
    return result.processPayPalOrder.success
  },
  processPayPalOrderV2: async data => {
    const result = await client.request(PROCESS_PAYPAL_ORDER_V2, data)
    return result.processPayPalOrderV2.success
  },
  processPayPalOrderV3: async data => {
    const result = await client.request(PROCESS_PAYPAL_ORDER_V3, data)
    return result.processPayPalOrderV3.success
  },
  deletePaymentMethod: async data => {
    const { inactivatePaymentInformation } = await client.request(
      DELETE_PAYMENT_METHOD,
      data
    )
    return inactivatePaymentInformation
  },
})
