/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState, useEffect } from 'react'
import { Radio } from 'semantic-ui-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { navigate } from 'gatsby'
// Components
import {
  Primary as SubmitButton,
  Basic as CancelButton,
  StyledInput,
  StyledSelect,
} from '../'
import editIcon from '../../svg/Edit Icon.svg'
import infoIcon from '../../svg/InfoIcon.svg'
import EnterEnrollerId from './EnterEnrollerId'
import VerifyDeleteFirebaseUser from './VerifyDeleteFirebaseUser'
import VerifyChangeLocaleModal from './VerifyChangeLocaleModal'
// Context
import { usePrismic } from '../../context/PrismicContext'
import { useAuthContext } from '../../context/AuthContext'
import { useStoreContext } from '../../context/StoreContext'
import { useCartContext } from '../../context/CartContext'
// Hooks
import useShowError from '../../hooks/useShowError'
// Styles
import {
  Container,
  ErrorMsg,
  FieldWrapper,
  Logo,
  StyledForm,
  StyledModal,
  StyledText,
  StyledTextBold,
  StyledImage,
  ThemedPopup,
} from './Verification.styles'
import { StyledCheckboxLabel } from '../../styles/RegisterPage.styles'
// Utils
import {
  buildExistingCustomer,
  buildNewCustomer,
} from '../../utils/validations'
import { findStoreByLocale } from '../../utils/storeHelpers'
import { addPotentialEnrollment } from '../../utils/enrollmentHelpers'
import { getLanguageCountryMap } from '../MarketPicker/languageData'

const ExistingQ = ({ isOpen, getUserCallback }) => {
  const {
    prismicData: {
      prismicGeneral,
      prismicExistingQModal: {
        cancel: cancelText,
        continue: continueText,
        first_name,
        last_name,
        logo,
        no: noText,
        placeholder,
        yes: yesText,
      },
      prismicMarketAndLanguage,
    },
    storeLocale,
    langAndCountry,
    saveLangAndCountry,
  } = usePrismic()
  const { handleChangeStore } = useStoreContext()
  const { cartData, autoShipItems } = useCartContext()

  const languageCountryMap = getLanguageCountryMap(prismicMarketAndLanguage)
  const localeArray = langAndCountry.split('-')
  const parsedLanguage = localeArray[0]
  const parsedCountryCode = localeArray[1]

  const storeLocaleArray = storeLocale.split('-')
  const parsedStoreLanguage = storeLocaleArray[0]
  const parsedStoreCountryCode = storeLocaleArray[1].toUpperCase()

  const [countryCode, setCountryCode] = useState(parsedCountryCode)
  const [languageCode, setLanguageCode] = useState(parsedLanguage)

  // update language to match new selected country options
  useEffect(() => {
    const sameLanguage = languageCountryMap[countryCode]?.find(
      item => item.languageCode === languageCode
    )
    setLanguageCode(
      sameLanguage
        ? sameLanguage?.languageCode
        : languageCountryMap[countryCode][0]?.languageCode
    )
  }, [countryCode])

  const currentLangAndCountry = `${parsedLanguage}_${parsedCountryCode}`
  const currentStoreCode = `${parsedStoreLanguage}_${parsedStoreCountryCode}`
  const magentoStoreOfSelectedCountry = findStoreByLocale(
    countryCode,
    prismicMarketAndLanguage.markets
  )

  const storeCodeOfSelectedCountry = `${languageCode}_${magentoStoreOfSelectedCountry?.store_code}`
  const countryCodeOfSelectedCountry = `${languageCode}_${magentoStoreOfSelectedCountry?.country_code}`
  const langAndCountryCode = `${languageCode}-${countryCode}`

  const [open, setOpen] = useState(isOpen)
  const [isLoading, setIsLoading] = useState(false)

  const [isEnterEnrollerIdOpen, setIsEnterEnrollerIdOpen] = useState(false)
  const [enrollerId, setEnrollerId] = useState('')
  const [areYouAReferral, setAreYouAReferral] = useState(true)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const [isVerifyCancelModalOpen, setIsVerifyCancelModalOpen] = useState(false)
  const [isDeleteFirebaseUserLoading, setIsDeleteFirebaseUserLoading] =
    useState(false)
  const [isVerifyChangeLocaleModalOpen, setIsVerifyChangeLocaleModalOpen] =
    useState(false)

  const {
    handleAddNewUser,
    manageReferral: { referralData, isReferral },
    getCurrentFirebaseUserEmail,
    deleteCurrentFirebaseUser,
  } = useAuthContext()

  const [error, setError, showError] = useShowError()
  const [isNewSignUp, setIsNewSignUp] = useState(false)

  const getIsNewSignUpFromStorage = () => {
    const value = localStorage.getItem('isNewSignUp')
    if (value === null) return setIsNewSignUp(false)
    setIsNewSignUp(value === 'true' ? true : false)
  }

  useEffect(() => {
    getIsNewSignUpFromStorage()
  }, [])

  const handleSetResolvers = () => ({
    resolver: !isNewSignUp
      ? yupResolver(buildExistingCustomer(prismicGeneral))
      : yupResolver(buildNewCustomer(prismicGeneral)),
    defaultValues: {
      firstName: referralData?.firstname ?? '',
      lastName: referralData?.lastname ?? '',
      countryCode: parsedCountryCode?.toLowerCase() || 'us',
      languageCode: languageCode || 'en',
    },
  })

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(handleSetResolvers())

  useEffect(() => {
    if (languageCountryMap[countryCode].length > 1) {
      setLanguageCode(languageCountryMap[countryCode][0].languageCode)
    }
    setLanguageCode(languageCountryMap[countryCode][0].languageCode)
  }, [countryCode])

  const resetAndShowError = message => {
    reset()
    setError(message)
  }

  const onSubmit = data => {
    getUserCallback(data.customer, resetAndShowError)
  }

  const handleClose = () => {
    setIsVerifyCancelModalOpen(true)
  }

  const handleVerifyDeleteFirebaseUser = async () => {
    setIsDeleteFirebaseUserLoading(true)
    await deleteCurrentFirebaseUser()
    setIsVerifyCancelModalOpen(false)
    setOpen(false)
    setIsDeleteFirebaseUserLoading(false)
    navigate('/')
  }

  const handleIsNewUser = async userInfo => {
    setIsLoading(true)
    const userInfoWithEnrollerId = {
      ...userInfo,
      languageCode,
      enrollerLegacyAssociateId: enrollerId ? parseInt(enrollerId) : null,
    }
    await handleAddNewUser(userInfoWithEnrollerId).then(() =>
      setIsLoading(false)
    )
    referralData?.type === 'ambassador' &&
      localStorage.setItem('persistEnrollerData', 'true')
    handleChangeStore(currentLangAndCountry, countryCodeOfSelectedCountry)
    localStorage.removeItem('guestCartId')
    saveLangAndCountry(langAndCountryCode)
  }

  const verifyChangeOfLocale = e => {
    e.preventDefault()
    if (
      (cartData?.items?.length > 0 || autoShipItems.length > 0) &&
      currentStoreCode !== storeCodeOfSelectedCountry
    ) {
      setIsVerifyChangeLocaleModalOpen(true)
    } else {
      handleSubmit(handleIsNewUser)()
    }
  }

  const handleVerifyChangeLocale = () => {
    handleSubmit(handleIsNewUser)()
    localStorage.removeItem('autoShipState')
  }

  // handle enroller data
  const handleOnSaveEnrollerId = value => {
    if (isNaN(value)) return
    setEnrollerId(value)
    setIsEnterEnrollerIdOpen(false)
  }

  const handleCloseEnrollerId = (parentValue, childValue) => {
    if (parentValue === childValue) return setIsEnterEnrollerIdOpen(false)
    setEnrollerId(parentValue)
    return setIsEnterEnrollerIdOpen(false)
  }

  useEffect(() => {
    if (areYouAReferral) return
    setEnrollerId('')
  }, [areYouAReferral])

  const firebaseEmail = getCurrentFirebaseUserEmail()
  useEffect(() => {
    if (!firebaseEmail) return
    addPotentialEnrollment({
      email: firebaseEmail || '',
      firstName: referralData?.firstname || '',
      lastName: referralData?.lastname || '',
      slug: referralData?.store || '',
      associateType: referralData?.type || '',
      ambassadorName: referralData?.ambassadorName || '',
      isReferral,
    })
  }, [firebaseEmail])

  // TODO - Add loading state to button for non-existing customer
  return (
    <StyledModal
      closeIcon
      centered={false}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={handleClose}
      open={open}
    >
      <Container direction="column">
        <Logo src={logo.url} />
        {!!isNewSignUp ? (
          <>
            <StyledForm onSubmit={verifyChangeOfLocale}>
              <FieldWrapper>
                <ErrorMsg>{errors.firstName?.message}</ErrorMsg>
                <StyledInput
                  {...register('firstName')}
                  placeholder={first_name[0].text}
                  onFocus={() => setError('')}
                  autoFocus
                  margin="0"
                />
              </FieldWrapper>

              <FieldWrapper>
                <ErrorMsg>{errors.lastName?.message}</ErrorMsg>
                <StyledInput
                  {...register('lastName')}
                  placeholder={last_name[0].text}
                  onFocus={() => setError('')}
                  margin="0.5rem 0 1rem 0"
                />
              </FieldWrapper>
              <FieldWrapper>
                <StyledSelect
                  {...register('countryCode')}
                  value={countryCode}
                  onChange={e => {
                    setCountryCode(e.target.value)
                    setValue('countryCode', e.target.value)
                  }}
                >
                  {prismicMarketAndLanguage?.markets.map(item => (
                    <option key={item.country_code} value={item.country_code}>
                      {item.label}
                    </option>
                  ))}
                </StyledSelect>
              </FieldWrapper>
              <FieldWrapper>
                {languageCountryMap[countryCode].length > 1 ? (
                  <StyledSelect
                    {...register('languageCode')}
                    value={languageCode}
                    onChange={e => setLanguageCode(e.target.value)}
                  >
                    {languageCountryMap[countryCode].map(item => (
                      <option key={item.languageCode} value={item.languageCode}>
                        {item.label}
                      </option>
                    ))}
                  </StyledSelect>
                ) : (
                  <StyledInput
                    disabled
                    value={languageCountryMap[countryCode][0].label}
                  />
                )}
              </FieldWrapper>

              {referralData?.store.length > 0 ? (
                <Container direction="column">
                  <StyledText>{prismicGeneral.msg_referred_by}</StyledText>
                  <Container padding="0">
                    {enrollerId.length > 0 && !isNaN(enrollerId) ? (
                      <StyledTextBold>{`${prismicGeneral.label_referrers_id_number}: ${enrollerId}`}</StyledTextBold>
                    ) : (
                      <StyledTextBold>
                        {referralData?.ambassadorName ?? ''}
                      </StyledTextBold>
                    )}
                    <StyledImage
                      src={editIcon}
                      onClick={() => setIsEnterEnrollerIdOpen(true)}
                      style={{ marginLeft: 12 }}
                    />
                  </Container>
                </Container>
              ) : (
                <Container
                  direction="column"
                  maxWidth="300px"
                  padding="16px 0 16px 0"
                >
                  <StyledTextBold>
                    {prismicGeneral.msg_were_you_referred_to_q_sciences}
                  </StyledTextBold>
                  <Container justify="space-between" padding="8px 0">
                    <Radio
                      checked={areYouAReferral}
                      label={
                        <StyledCheckboxLabel>
                          {yesText[0].text}
                        </StyledCheckboxLabel>
                      }
                      onChange={() => setAreYouAReferral(true)}
                    />
                    <StyledInput
                      style={{ flex: 1, margin: '0 8px' }}
                      placeholder={prismicGeneral.label_referrers_id_number}
                      onFocus={() => setAreYouAReferral(true)}
                      value={enrollerId}
                      onChange={e => setEnrollerId(e.target.value)}
                    />
                    <ThemedPopup
                      content={prismicGeneral.msg_unknown_ambassador_id}
                      on="click"
                      onClose={() => setIsPopupOpen(false)}
                      onOpen={() => setIsPopupOpen(true)}
                      open={isPopupOpen}
                      position="bottom right"
                      hideOnScroll={true}
                      trigger={<StyledImage src={infoIcon} />}
                    />
                  </Container>
                  <Container
                    padding="none"
                    justify="flex-start"
                    onClick={() => setAreYouAReferral(false)}
                  >
                    <Radio
                      checked={!areYouAReferral}
                      label={
                        <StyledCheckboxLabel>
                          {noText[0].text}
                        </StyledCheckboxLabel>
                      }
                      onChange={() => setAreYouAReferral(false)}
                      style={{ marginRight: 4, marginBottom: 0 }}
                    />
                    <StyledText style={{ marginLeftt: 4, marginBottom: 2 }}>
                      - {prismicGeneral.i_found_q_sciences_all_by_myself}
                    </StyledText>
                  </Container>
                </Container>
              )}

              <SubmitButton
                disabled={
                  isLoading ||
                  isDeleteFirebaseUserLoading ||
                  (areYouAReferral && !enrollerId && !referralData?.store)
                }
                loading={isLoading}
              >
                {continueText[0].text}
              </SubmitButton>
            </StyledForm>
          </>
        ) : null}

        {error && showError()}
        <Container direction="column" padding="0">
          {!isNewSignUp ? (
            <>
              <StyledForm onSubmit={handleSubmit(onSubmit)}>
                <ErrorMsg>{errors.customer?.message}</ErrorMsg>
                <StyledInput
                  {...register('customer')}
                  placeholder={placeholder[0].text}
                  onFocus={() => setError('')}
                  autoFocus
                />
                <SubmitButton type="submit">
                  {continueText[0].text}
                </SubmitButton>
              </StyledForm>
            </>
          ) : null}
          {/* <CancelButton style={{ marginTop: '0' }} onClick={handleCancel}>
            {cancelText[0].text}
          </CancelButton> */}
        </Container>
      </Container>
      <EnterEnrollerId
        open={isEnterEnrollerIdOpen}
        onClose={handleCloseEnrollerId}
        value={enrollerId}
        onChange={e => setEnrollerId(e.target.value)}
        onSave={handleOnSaveEnrollerId}
      />
      <VerifyDeleteFirebaseUser
        open={isVerifyCancelModalOpen}
        onClose={() => {
          setIsVerifyCancelModalOpen(false)
        }}
        yesOnClick={handleVerifyDeleteFirebaseUser}
      />
      <VerifyChangeLocaleModal
        open={isVerifyChangeLocaleModalOpen}
        onClose={() => setIsVerifyChangeLocaleModalOpen(false)}
        yesOnClick={handleVerifyChangeLocale}
      />
    </StyledModal>
  )
}

export default ExistingQ
