import {
  BASIC_INFO,
  CHECK_IF_USER_EXISTS,
  GET_CUSTOMER_ORDERS,
  GET_ORDER,
  GET_ORDERS_PAGE_INFO,
  ORDER_HISTORY,
  CREATE_CUSTOMER_ADDRESS,
  CREATE_CUSTOMER,
  DELETE_CUSTOMER_ADDRESS,
  GENERATE_CUSTOMER_TOKEN,
  GET_FIREBASE_TOKEN,
  GET_MAGENTO_TOKEN_FROM_FIREBASE_TOKEN,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ADDRESS,
  GET_SINGLE_ORDER_INFORMATION,
} from '../graphql/customer'

type UserData = {
  email: string
  password: string
  firstname?: string
  lastname?: string
}

type FirebaseTokenResponse = {
  message: string
  status: string
  firebase_token?: string
}

type MagentoFirebaseTokenProps = {
  associate_id: number
  first_name: string
  jwt_token: string
  last_name: string
}

type MagentoFirebaseTokenResponse = {
  customer_token: string
  isNewCustomer: boolean
  message: string
  status: string
}

type CreateUpdateCustomerResponse = {
  email: string
  firstname: string
  lastname: string
}

export const User = client => ({
  addUser: async (
    variables: UserData
  ): Promise<CreateUpdateCustomerResponse> => {
    let { createCustomer } = await client.request(CREATE_CUSTOMER, variables)
    return createCustomer.customer
  },
  basicInformation: async () => {
    let user
    await client
      .request(BASIC_INFO)
      .then(({ customer }) => (user = customer))
      .catch(e => console.log(e))
    return user
  },
  basicInformationWithToken: async (token: string) => {
    let user
    await client
      .request(BASIC_INFO, null, {
        authorization: `Bearer ${token}`,
      })
      .then(({ customer }) => (user = customer))
      .catch(e => console.log(e))
    return user
  },
  createCustomerAddress: async data =>
    await client.request(CREATE_CUSTOMER_ADDRESS, data),
  deleteCustomerAddress: async id =>
    await client.request(DELETE_CUSTOMER_ADDRESS, id),
  getCustomerOrders: async () => await client.request(GET_CUSTOMER_ORDERS),
  getCustomerOrderById: async variables =>
    await client.request(GET_ORDER, variables),
  getFirebaseToken: async (
    variables: UserData
  ): Promise<FirebaseTokenResponse> =>
    await client.request(GET_FIREBASE_TOKEN, variables),
  getMagentoTokenFromFirebaseToken: async (
    variables: MagentoFirebaseTokenProps
  ): Promise<MagentoFirebaseTokenResponse> => {
    let { getCustomerToken } = await client.request(
      GET_MAGENTO_TOKEN_FROM_FIREBASE_TOKEN,
      variables
    )
    return { ...getCustomerToken }
  },
  getOrdersPageInfo: async () => await client.request(GET_ORDERS_PAGE_INFO),
  getToken: async (variables: UserData): Promise<string> => {
    let { generateCustomerToken } = await client.request(
      GENERATE_CUSTOMER_TOKEN,
      variables
    )
    return generateCustomerToken.token
  },
  isEmailAvailable: async (email: string): Promise<boolean> =>
    await client.request(CHECK_IF_USER_EXISTS, { email }),
  orderHistory: async variables =>
    await client.request(ORDER_HISTORY, variables),
  getSingleOrder: async data => {
    const {
      customer: {
        orders: {
          items: [order],
        },
      },
    } = await client.request(GET_SINGLE_ORDER_INFORMATION, data)
    return order
  },
  setToken: (token: string) =>
    client.setHeader('authorization', `Bearer ${token}`),
  setStoreView: storeViewCode => client.setHeader('store', `${storeViewCode}`),
  updateCustomer: async (variables: {
    firstname?: string
    lastname?: string
    dob?: string
  }): Promise<CreateUpdateCustomerResponse> =>
    await client.request(UPDATE_CUSTOMER, variables),
  updateCustomerAddress: async data =>
    await client.request(UPDATE_CUSTOMER_ADDRESS, data),
  unSetToken: () => client.setHeaders({}),
})
