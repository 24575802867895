import React, { useState, useEffect, useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// Components
import { Loading } from '../components'
// Types
import {
  PrismicContextType,
  PrismicData,
} from '../types/contexts/PrismicContextTypes'
// Utils
import { getStoreView } from '../utils/i18n/stores'
// Services
import { QService } from '../services/q-services'

const Prismic = React.createContext<Partial<PrismicContextType>>({})

export const usePrismic = () => useContext(Prismic)

const PrismicProvider = ({ children }) => {
  // storeLocale is used to hook up to the correct Magento and Prismic
  const [storeLocale, setUserLocale] = useState(null)
  // langAndCountry is to get the correct country for addresses and formatting currency
  const [langAndCountry, setLangAndCountry] = useState(null)
  const [magentoStoreName, setMagentoStoreName] = useState('')
  const [prismicLoading, setPrismicLoading] = useState(true)
  const [prismicData, setPrismicData] = useState({})

  useEffect(() => {
    const storeCode = getStoreView(localStorage, navigator.language).store_code
    const storeName = getStoreView(localStorage, navigator.language).store_name
    const langAndCountryFromStorage =
      localStorage.getItem('LANG_AND_COUNTRY') ?? storeCode.replace('_', '-')
    if (
      storeCode === 'Event' ||
      storeCode === 'vipStore' ||
      storeCode === 'ot_view' ||
      storeCode === 'testOpsView'
    ) {
      setUserLocale('en-us')
      setMagentoStoreName('US English')
      setLangAndCountry('en-US')
    } else {
      setUserLocale(storeCode.replace('_', '-').toLowerCase())
      setMagentoStoreName(storeName)
      setLangAndCountry(langAndCountryFromStorage)
    }
  }, [])

  const allPrismicData: PrismicData = useStaticQuery(graphql`
    query GetAllPrismicData {
      prismicHomepage: allPrismicHomepage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicSideMenu: allPrismicSideMenu {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAboutPage: allPrismicAboutPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProgramPage: allPrismicProgramPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicSciencePage: allPrismicSciencePage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicContactUsPage: allPrismicContactUsPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicEnterCodeModal: allPrismicEnterCodeModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicExistingQModal: allPrismicExistingQModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicSendVerificationModal: allPrismicSendVerificationModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicFooter: allPrismicFooter {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicLoginPage: allPrismicLoginPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProfilePage: allPrismicProfilePage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicCartPage: allPrismicCartPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProductsPage: allPrismicProductsPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicProductDetailPage: allPrismicProductDetailPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicOrderHistoryPage: allPrismicOrderHistoryPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicMarketAndLanguage: allPrismicMarketAndLanguage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAddUpdateShippingPage: allPrismicAddUpdateShippingPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicShippingInfoPage: allPrismicShippingInfoPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicPaymentInfoPage: allPrismicPaymentInfoPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAddCardPage: allPrismicAddCardPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicRegisterPage: allPrismicRegisterPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicOrderPage: allPrismicOrderPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicErrorData: allPrismicErrorData {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicForgotPasswordPage: allPrismicForgotPasswordPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicPrivacyPolicyPage: allPrismicPrivacyPolicyPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicTermsPage: allPrismicTermsPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAmbassadorModal: allPrismicAmbassadorModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAutoshipPage: allPrismicAutoshipPage {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicArqModal: allPrismicArqModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicCountriesPerStore: allPrismicCountriesPerStore {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicAmbassadorEnroll: allPrismicAmbassadorEnroll {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicNexioRedirect: allPrismicNexioRedirect {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicPacksModal: allPrismicPacksModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicPickYourPerksModal: allPrismicPickYourPerksModal {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicGeneral: allPrismicGeneral {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicDisclaimer: allPrismicDisclaimer {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
      prismicQHome: allPrismicQHome {
        edges {
          node {
            lang
            dataRaw
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!storeLocale) return
    const buildAllPrismicData = () => {
      let prismicDataObject = {}

      Object.entries(allPrismicData).forEach(el => {
        const prismicName = el[0]
        const prismicData = el[1].edges.find(
          raw => raw.node.lang === storeLocale
        )

        prismicDataObject[`${prismicName}`] = prismicData?.node?.dataRaw
      })
      setPrismicData(prismicDataObject)
    }

    buildAllPrismicData()
  }, [storeLocale])

  useEffect(() => {
    if (prismicData && Object.keys(prismicData).length > 0) {
      setPrismicLoading(false)
    }
  }, [prismicData])

  // countries
  const storeCountryCode = storeLocale?.slice(-2) || 'us'

  const [activeCountries, setActiveCountries] = useState([])
  const language = 'en'
  const variables = { language }
  const getCountryData = async () => {
    const response = await QService.Countries.getActiveCountries(variables)
    setActiveCountries(response)
  }

  useEffect(() => {
    getCountryData()
  }, [])

  const saveLangAndCountry = locale => {
    setLangAndCountry(locale)
    localStorage.setItem('LANG_AND_COUNTRY', locale)
  }
  const value = {
    activeCountries,
    storeLocale,
    storeCountryCode,
    langAndCountry,
    saveLangAndCountry,
    magentoStoreName,
    prismicData,
  }
  if (prismicLoading)
    return <Loading loading={prismicLoading} message={'Loading'} />
  return (
    <Prismic.Provider value={value}>
      {!prismicLoading && children}
    </Prismic.Provider>
  )
}
export default PrismicProvider
