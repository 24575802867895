export const getLanguageCountryMap = data => {
  const english = { languageCode: 'en', label: data?.english }
  const spanish = { languageCode: 'es', label: data?.spanish }
  const french = { languageCode: 'fr', label: data?.french }
  const czech = { languageCode: 'cs', label: data?.czech }
  const dutch = { languageCode: 'nl', label: data?.dutch }
  const german = { languageCode: 'de', label: data?.german }
  const italian = { languageCode: 'it', label: data?.italian }
  const swedish = { languageCode: 'sv', label: data?.swedish }
  const norwegian = { languageCode: 'no', label: data?.norwegian }
  const japanese = { languageCode: 'ja', label: data?.japanese }

  // TODO - add spanish back to usLanguages when it is ready
  // The supported languages per market are in accordance with matching Magento Stores and Prismic documents
  // USA
  // const usLanguages = [english, spanish]
  const usLanguages = [english]
  // Australia
  const auLanguages = [english]
  // New Zealand
  const nzLanguages = [english]
  // Canada
  const caLanguages = [english, french]
  // Austria
  // const atLanguages = [german, english]
  const atLanguages = [english]
  // Belgium
  // const beLanguages = [dutch, english]
  const beLanguages = [english]
  // Czech Republic
  // const czLanguages = [czech, english]
  const czLanguages = [english]
  // Denmark
  const dkLanguages = [english]
  // Spain
  // const esLanguages = [spanish, english]
  const esLanguages = [english]
  // France
  // const frLanguages = [french, english]
  const frLanguages = [english]
  // Germany
  // const deLanguages = [german, english]
  const deLanguages = [english]
  // Greece
  const grLanguages = [english]
  // Ireland
  const ieLanguages = [english]
  // Italy
  // const itLanguages = [italian, english]
  const itLanguages = [english]
  // Latvia
  const lvLanguages = [english]
  // Lithuania
  const ltLanguages = [english]
  // Netherlands
  // const nlLanguages = [dutch, english]
  const nlLanguages = [english]
  // Poland
  const plLanguages = [english]
  // Portugal
  const ptLanguages = [english]
  // Sweden
  // const seLanguages = [swedish, english]
  const seLanguages = [english]
  // Switzerland
  const chLanguages = [english]
  // EU NFR
  const nfrLanguages = [english]
  // Norway
  const noLangiages = [english]
  // UK
  const gbLanguages = [english]
  // Mexico
  const mxLanguages = [english]

  // The following 2-digit country codes match the country_code for each market in prismic in the Market and Language document
  return {
    US: usLanguages,
    AU: auLanguages,
    NZ: nzLanguages,
    CA: caLanguages,
    AT: atLanguages,
    BE: beLanguages,
    CZ: czLanguages,
    DK: dkLanguages,
    ES: esLanguages,
    FR: frLanguages,
    DE: deLanguages,
    GR: grLanguages,
    IE: ieLanguages,
    IT: itLanguages,
    LV: lvLanguages,
    LT: ltLanguages,
    NL: nlLanguages,
    PL: plLanguages,
    PT: ptLanguages,
    SE: seLanguages,
    CH: chLanguages,
    EU: nfrLanguages,
    NO: noLangiages,
    GB: gbLanguages,
    MX: mxLanguages,
  }
}
