import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'
// Context
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Hooks
import useShowProductPrice from '../../hooks/useShowProductPrice'
import useProductPriceSum from '../../hooks/useProductPriceSum'
import { DontDeleteLastItemModal } from '../Autoship'
// Styles
import {
  ButtonsWrapper,
  CardInfoContainer,
  CardWrapper,
  Count,
  MinusButton,
  OutOfStockOverlay,
  PlusButton,
  RemoveButton,
  RotatedText,
  // StyledCheckbox,
  Title,
} from './CartProductCard.styles'
// Utils
import { enrollSkus } from '../../utils/constants'
import { OUT_OF_STOCK } from '../../utils/productHelpers'
import PacksModal from './PacksModal'

const CartProductCard = ({ item, useWholesale }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [updateAdd, setUpdateAdd] = useState(false)
  const [updateSub, setUpdateSub] = useState(false)
  // const [autoshipChecked, setAutoshipChecked] = useState(false)
  const [isAutoShipLocation, setIsAutoShipLocation] = useState(false)
  const [packsModalOpen, setPacksModalOpen] = useState(false)
  const [packsInCart, setPacksInCart] = useState([])
  const location = useLocation()
  const [openDontDeleteModal, setOpenDontDeleteModal] = useState(false)

  const {
    prismicData: {
      prismicCartPage: { out_of_stock, remove: removeText },
      prismicGeneral,
    },
  } = usePrismic()

  const { product, quantity, uid } = item

  const configOption = item?.configurable_options?.[0] ?? null

  const {
    // autoship,
    image,
    name,
    sku,
    stock_status,
    url_key,
  } = product
  const {
    cartData,
    cartId,
    manageCart,
    addToAutoShip,
    buildCart,
    downgradeAmbassadorRemoveItems,
    downgradeUserRemovePcFee,
    removeFromAutoShip,
    doesExceedMaxOrder,
    setIsMaxOrderModalOpen,
    autoShipItems,
  } = useCartContext()

  const { ProductPricing } = useShowProductPrice(product, useWholesale)
  const { productPriceSum } = useProductPriceSum(product, 1, useWholesale)

  const isCartMaxedOut = doesExceedMaxOrder(productPriceSum, 'cart')
  const isAutoShipMaxedOut = doesExceedMaxOrder(productPriceSum, 'autoship')

  const handleUpdateQuantity = async (increase = true) => {
    if (increase && isCartMaxedOut) {
      return setIsMaxOrderModalOpen(true)
    }
    increase ? setUpdateAdd(true) : setUpdateSub(true)

    await manageCart
      .updateCartItems(item, increase, cartId, buildCart, prismicGeneral)
      .then(() => (increase ? setUpdateAdd(false) : setUpdateSub(false)))
  }

  const removePcOrAmbFee = async () => {
    if (sku === enrollSkus.pcFee) {
      return downgradeUserRemovePcFee(uid, packsInCart)
    }
    if (sku === enrollSkus.ambPremiumSku || sku === enrollSkus.ambBasicSku) {
      // downgrade magento associate to current type, remove amb fee, arq fee, and myQFitFee
      return await manageCart
        .findIdsOfAmbEnrollmentProducts(cartData?.items)
        .then(({ arqId, myQFitId }) =>
          downgradeAmbassadorRemoveItems(uid, arqId, myQFitId, packsInCart)
        )
    }
  }

  const checkForPacks = async () => {
    const packs = cartData?.items?.filter(item => {
      return item.product.categories?.some(
        category => category.name === 'Packs'
      )
    })
    if (packs.length > 0) {
      setPacksInCart(packs.map(item => item.uid))
      setPacksModalOpen(true)
    } else {
      await removePcOrAmbFee()
    }
  }

  const onPacksClosed = () => {
    setPacksModalOpen(false)
    setIsLoading(false)
  }

  const onYesClosed = async () => {
    setPacksModalOpen(false)
    await removePcOrAmbFee()
  }

  const handleRemoveFromCart = async () => {
    setIsLoading(true)
    if (
      sku === enrollSkus.pcFee ||
      sku === enrollSkus.ambBasicSku ||
      sku === enrollSkus.ambPremiumSku
    ) {
      await checkForPacks(sku)
    } else {
      await manageCart.removeItemFromCart(
        uid,
        cartId,
        buildCart,
        prismicGeneral
      )
    }
  }

  const handleRemoveFromAutoShip = async () => {
    if (autoShipItems.length === 1) {
      return setOpenDontDeleteModal(true)
    }
    removeFromAutoShip(sku, name)
  }

  const handleUpdateASQuantity = (increase = true) => {
    if (increase && isAutoShipMaxedOut) {
      return setIsMaxOrderModalOpen(true)
    }
    if (quantity === 1 && !increase) return
    let newQuantity = increase ? 1 : -1
    addToAutoShip(product, newQuantity)
  }

  // const handleAutoshipChecked = () => {
  //   setAutoshipChecked(!autoshipChecked)
  // }

  useEffect(() => {
    setIsAutoShipLocation(location.pathname === '/subscriptions')
  }, [location])

  const isOutOfStock = stock_status === OUT_OF_STOCK

  const buildName = !!configOption
    ? `${name} - ${configOption.value_label}`
    : `${name}`

  const shouldHideAddSubtract = Object.values(enrollSkus).includes(sku)

  const shouldHideRemove = sku === enrollSkus.arq || sku === enrollSkus.myQFit

  return (
    <CardWrapper>
      <OutOfStockOverlay hidden={!isOutOfStock}>
        <RotatedText>{out_of_stock[0].text}</RotatedText>
      </OutOfStockOverlay>
      <Title data-qa={`title-${sku}`}>
        <span>{buildName}</span>
      </Title>
      <Image
        as={Link}
        data-qa={`image-${sku}`}
        size="small"
        src={image.url}
        style={{ paddingTop: '2em' }}
        to={`/products/${url_key}`}
      />
      <CardInfoContainer>
        <ProductPricing isCart={true} quantity={quantity} />
        {shouldHideAddSubtract ? null : (
          <ButtonsWrapper hidden={isOutOfStock}>
            <MinusButton
              loading={updateSub}
              disabled={updateSub || updateAdd}
              onClick={() =>
                uid
                  ? handleUpdateQuantity(false)
                  : handleUpdateASQuantity(false)
              }
              data-qa={`minusButton-${sku}`}
            >
              <span style={{ fontSize: '2em' }}>-</span>
            </MinusButton>
            <Count>{quantity}</Count>
            <PlusButton
              data-qa={`plusButton-${sku}`}
              disabled={updateAdd || updateSub}
              loading={updateAdd}
              onClick={uid ? handleUpdateQuantity : handleUpdateASQuantity}
            >
              <span style={{ fontSize: '2em' }}>+</span>
            </PlusButton>
          </ButtonsWrapper>
        )}
        {shouldHideRemove ? null : (
          <RemoveButton
            loading={isLoading}
            disabled={isLoading}
            onClick={
              isAutoShipLocation
                ? handleRemoveFromAutoShip
                : handleRemoveFromCart
            }
            data-qa={`removeButton-${sku}`}
          >
            {removeText[0].text}
          </RemoveButton>
        )}
      </CardInfoContainer>
      <PacksModal
        open={packsModalOpen}
        yesOnClick={onYesClosed}
        noOnClick={onPacksClosed}
        onClose={onPacksClosed}
      />
      <DontDeleteLastItemModal
        open={openDontDeleteModal}
        onClose={() => setOpenDontDeleteModal(false)}
      />
    </CardWrapper>
  )
}

export default CartProductCard
